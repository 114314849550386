@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import './assets/style/App.module.css';


*{
    padding: 0;
    margin:0 ;
    box-sizing: border-box;
    background: transparent;
}
svg{
  margin: 5px;
}
.animation-public-app{
  animation:1s ease-in both;
  animation-timeline:view();
  animation-range:entry;
  animation-name:blurFadeIn;
  -webkit-animation:1s ease-in both;
}
 @keyframes blurFadeIn{
    0%{
        transform:scale(2.5);
        opacity: 0;
        filter:blur(5px);
    }
    100%{
        transform:scale(1);
        opacity:1;
        filter:blur(0px);


    }
 }
body{
  
    font-family: system-ui;
}
.mainSection{
  min-height: 60vh;

}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.termsBtn{
  padding: 10px 20px;
  background: var(--tertiary-color);
  border-radius: 25px;
  color: var(--dark-blue) !important;
}

.accordion-public-class .accordion-button::after{
  background-image: var(--accordion-btn-icon) 
  
} 

.postSelectForm .css-b62m3t-container .css-13cymwt-control{
  border: 1px solid var(--secondary-color);
  padding: 5px;
  margin-top: 6px;
 
}
.postSelectForm:hover.css-b62m3t-container .css-13cymwt-control{
  border: 1px solid var(--secondary-color);
  padding: 5px !important;
  margin-top: 6px;
}


.postSelectForm .css-b62m3t-container .css-13cymwt-control ::placeholder{
    color: var(--primary-color);
}

.jobRentSelect .css-b62m3t-container .css-13cymwt-control{
  border-radius: 10px;
border: 3px solid var(--secondary-color);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
}

a{
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .slick-list{
  width: 95% !important;
  margin: auto !important;
} */

.categoryHomePage .slick-list{
  width: 95% !important;
  margin: auto !important;
}

.slick-prev{
  left: 20px;
  z-index: 1;
  font-size:39px;
  width: 25px;
}
.slick-next{
  right: 20px;
  width: 25px;

}

.fa-heart{
  color: rgb(223 47 47)  !important;
}

.slick-next:before, .slick-prev:before {
  font-size: 39px;
}
@media (max-width: 460px ){
  .slick-prev{
    left: 4px;
  }
  .slick-next{
    right: 4px;
  }

  .slick-next:before, .slick-prev:before {
    font-size: 25px;
  }
}
@media (min-width: 1316px)
{
  .slick-prev{
    left: 50px;
  }
  .slick-next{
    right: 50px;
  }
}