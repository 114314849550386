@import "../_App.module.scss";

.scrollToTopDiv {
  background-color: $secondary-color;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05) !important;
  border-radius: 50%;
  position: fixed;
  animation: scrollUpDown 2s infinite; /* Use scrollUpDown animation with 2 seconds duration and infinite iterations */
  bottom: 80px;
  right: 26.5px;
  width: 60px!important;
  height: 60px!important;
  border-radius: 54px!important;
  z-index: 9999;
  cursor: pointer;
  .arrowDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 12px;
    i {
      font-weight: bold;
      color: var(--white);
      font-size: 32px;
    }
  }
}
.arrowDiv svg{
  font-size:60px;
  margin-top:-10px;
  color:white;
}
@keyframes scrollUpDown {
  0% {
    bottom: 120px; 
  }
  50% {
    bottom: calc(19vh - 50px);  
  }
  100% {
    bottom: 118px; 
  }
}
@media (max-width:1024px){
  .scrollToTopDiv{
    right:14.5px !important;
  }
}

@media (max-width: 500px) {
   .scrollToTopDiv {
      position: fixed;
      bottom: 86px;
      right: 14px;
      width: 56px!important;
      height: 56px!important;

   }
    
}


@media (min-width: 600px) and (max-width: 1305){
  .scrollToTopDiv {
     position: fixed;
     bottom: 95px !important;
     right: 27px !important;
     width: 56px!important;
     height: 56px!important;

  }
   
}

@media (min-width: 472px) and (max-width: 601px ){
  .scrollToTopDiv {
    position: fixed;
    bottom: 92px;
    right: 25px;
    width: 59px !important;
    height: 59px !important;
  }
   
}