@import "../_App.module.scss";
.buttonContainer {
  border: none;

  // width: 70px;
  color: $white;
  // box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 14px;

  .iconUserDiv {
    i {
      padding: 5px;
      color: $white;

    }
  }
}
.categoryBtnDiv {
  display: flex;
  justify-content: flex-end;
  margin: 0px 17px;
}
.userloggedinBtn {
  background-color: transparent;
  padding: 10px;
  // border: 1px solid $white;
  height: 34px;
  // border-radius: 9px;
  width: 150px;
  color: $white ;
  
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  cursor: pointer;

  i {
    padding: 5px !important;
    color: $white  !important;
  }
}

@media (max-width: 768px) {
  // .buttonContainer {
  .categoryBtnDiv {
    margin: 0px 2px !important;
  }
  .categoryBtnDivAr {
    margin: 0px 16px !important;
  }
}
// }
