@import "../_App.module.scss";

.footer {
  background-color: $primary-color;
  color: $white;
  padding: 22px;
  //   width: 100%;
  height: fit-content;
}
.logoImage {
  height: 98px;
}
.footerIcon svg{
  font-size:36px;
  color:#fff;
}

.firstSection {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footerIcon {
  padding-top: 15px;
  a {
    margin: 9px;
  }
  i {
    color: $white;
    font-size: 22px;
  }
}

.mainSubscribe {
  input {
    background: #d9d9d9;
    border: 1px solid $black;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    min-width: 291px;
  }
}
.subscribeBtn {
  padding-top: 12px;
  width: 100%;
  button {
    background: $secondary-color;
    border-radius: 10px;
    padding: 10px;
    color: $primary-button-font-color ;
    width: 100%;
    border: none !important;
    font-weight: bold;
  }
}

.linksDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;

  a {
    color: $white !important;
    margin: 12px;
    font-size: 16px;
  }
}
.secondLinksDiv {
  padding-top: 13px;
}

.firstLinksDiv {
  padding-top: 13px;
}

.copyRightDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 52px;
}

.subscribed {
  background-color: $tertiary-color !important;
  display: flex;
  justify-content: center;
  align-items: baseline;
  i {
    font-size: 18px;
    color: $primary-color;
    font-weight: bold;
  }
  p {
    margin: 0;
    padding: 0px 10px;
    font-size: 16px;
    color: $primary-color;
    font-weight: bold;
  }
}
.warnEmail {
  color: $danger-color;
}

@media (min-width: 250px) and (max-width: 1200px) {
  .subscribeParagraph {
    text-align: center;
    padding-top: 10px;
  }

  .linksDiv {
    a {
      font-size: 14px;
    }
  }
  .copyRightDiv {
    font-size: 14px;
  }
  .firstLinksDiv {
    display: flex;
    align-items: center;
    width: max-content;
  }
  .secondLinksDiv {
    display: flex;
    align-items: center;
    // width: max-content;
  }
  .copyRightParagraph {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .linksDivMobile {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .linksDiv {
    display: none !important;
  }
  .linksDivMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 9px;

    a {
      color: $white !important;
      margin: 12px;
      font-size: 14px;
    }
  }
}

@media (min-width: 358px) and (max-width: 365px) {
  .linksDivMobile {
    a {
      font-size: 13.5px;
    }
  }
}

@media (max-width: 358px) {
  .firstLinksDiv {
    width: min-content !important;
  }
}
.footerRow{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
@media (max-width:1024px){
  .footerRow{
    flex-direction:column !important;
    align-items:center !important;

  }
}