/* main color in arab georgia website  */
$primary-color: #06163F;
$secondary-color : #7E0517;
$transparent-color : rgba(190,0,64 ,0.50);
$tertiary-color: #05436B;
$box-color: white;
$highlight-color: #565252;
$primary-button-font-color: white;
$sub-box-color :  rgba(247, 254, 255, 0.1);
$danger-color: red;
$success-color : green;
$black: black;
$white: #ffff;
$nav-color : #ffff;
$grey : #D9D9D9 ;